/* Ensure the last column is fixed and visible while scrolling */
/* .table th:last-child, .table td:last-child {
    position: -webkit-sticky;
    position: sticky;
    right: 0;
    background-color: #fff; 
    z-index: 10;
    width: 00px;  
  } */
  
  /* Optional: Adjust the width of the last column */
  /* .table th:last-child, .table td:last-child {
    width: 200px; 
  } */
  

   /* Container to enable horizontal scrolling  */
   /* .table-wrapper {
    overflow-x: auto;
    width: 100%;
  } */
   
  /* table {
    min-width: 100%; 
  } */
  
  
 /* td:last-child, tr th:last-child {

    position: -webkit-sticky;
    position: sticky;
  right: 0px;
    z-index: 9;
    background-color: #fff; 
   
    width:100px;
    
    border-left-width: 1px;
  
  } */
 
  .table-wrapper {
    overflow-x: auto; /* Ensures horizontal scrolling */
    width: 100%;
    padding: 0 !important;
    margin:0 !important;
    
   
  }
  
  table {
    box-sizing: border-box;
    min-width: 100%;
    padding: 0;
    margin: 0;
    border-spacing: 0; /* Remove potential gaps between table cells */
    border-collapse: collapse; /* Ensure there's no space between table cells */
  }
  
  .stick-table{
   td:last-child, th:last-child {
    position: -webkit-sticky;
    position: sticky;  
    right:0;
    /* width:100px, */
    background-color: #fff; 
   
  }
}
  

   /* td:last-child {
    width: auto;
  } */
