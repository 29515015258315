.data{
   @apply font-[600] 
}
.step::after{
    @apply !w-30-scale-mob md:!w-40-scale-924 !h-30-scale-mob md:!h-40-scale-924 font-[600];
    background-color: white !important;
    border:2px  solid #000000 !important;
    /* width:20px !important */
    
}
.step::before{
    /* @apply !w-171-scale-924; */
    background-color: #000000 !important;
    height: 2px !important;
    /* width:500px !important; */
 
   
}


/* 
.step::before {
    content: '';
    position: absolute;
    top: 50%;
    left: 50%;
    width: 100%;
    height: 2px;
    background-color: #ccc; 
    transform: translateX(-50%);
    z-index: -1; 
  }

  .step:first-child::before {
    content: none;
  } */