audio::-webkit-media-controls-play-button,
     audio::-webkit-media-controls-panel {
     background-color: #fff;
     color: #fff;

     }
  

     audio::-webkit-media-controls-menu-button {
        display: none;
      }