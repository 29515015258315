@tailwind base;
@tailwind components;
@tailwind utilities;



@font-face {
  font-family: 'inter';
  /* src: url('/fonts/Inter-Regular.ttf') format('truetype'); */
  src:url("../public/fonts/Inter-Regular.ttf")
}


body {
  margin: 0;
  font-family:  'inter',  sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /* overflow-y: scroll; */
  /* scrollbar-width: 0; */
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
html {
  scrollbar-width: none; /* Hide scrollbar in Firefox */
}

/* For WebKit browsers (Chrome, Safari) */
html::-webkit-scrollbar {
  display: none; /* Hide scrollbar in WebKit browsers */
}
/* .sticky-nav{
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  background-color: yellow;

} */
/* @media and screen */


