.card{
    /* box-shadow: 0px 0px 6px 0px #0000001F; */
    gap:clamp(10px,calc((100vw/1675) * 20),60px)

}
.form-fields{
    gap:clamp(10px,calc((100vw/1675) * 20),60px)
}
.form-gap-20{
  gap:clamp(10px,calc((100vw/1675) * 20),60px)
}
.form-footer{
box-shadow: 0px -2px 4.8px 0px #0000001A;
}
/* custom.css */
/* custom.css */
.custom-checkbox {
  @apply md:w-20-scale-924 md:h-20-scale-924 w-20-scale-mob h-20-scale-mob;
    appearance: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    /* width: 40px;
    height: 40px; */
    border-radius: 5px;
    border: 2px solid #d1d5db; /* Default border color */
    background-color: #ffffff; /* Default background color */
    cursor: pointer;
    display: inline-block;
    vertical-align: middle;
    transition: background-color 0.2s, border-color 0.2s;
    position: relative;
  }
  
  .custom-checkbox:checked {
    background-color: black; /* Checked background color */
    border: 0px solid #d1d5db;
   
  }
  
  .custom-checkbox:checked::after {
    content: '✓'; /* Unicode tick mark */
    display: block;
    color: #ffffff; /* Tick mark color */
    font-size: 25px; /* Tick mark size */
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }


/* Add this to your CSS file */
.custom-radio { 
   @apply md:w-20-scale-924 md:h-20-scale-924 w-20-scale-mob h-20-scale-mob;
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
 
  border: 2px solid #d1d5db;
  border-radius: 50%;
  position: relative;
  cursor: pointer;
  transition: background-color 0.2s, border-color 0.2s;
}

.custom-radio:checked {
  border-color: black; /* Border color when checked */
}

.custom-radio:checked::after {
  @apply w-10-scale-mob h-10-scale-mob md:w-10-scale-924 md:h-10-scale-924;

  content: '';
  display: block;
 
  border-radius: 50%;
  background: black; /* Inner circle color when checked */
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.rounded-top {
 border-radius: 10px 10px 0px 0px !important;

}
.rounded-bottom {
  border-radius: 0 0 10px 10px !important;
 
 }
 .input-focus:focus{
border-color:#C6C6C6 !important
 }