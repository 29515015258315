.nav-links{
    @apply text-14-scale-mob md:text-14-scale flex px-20-scale-mob md:px-20-scale min-h-44-scale gap-x-4 items-center text-blue-secondary-300 font-[600];
}
.nav-icons:hover{
  @apply text-black-primary bg-ligh-blue rounded-[10px]
}
.nav-icons-focused{

  @apply text-black-primary bg-ligh-blue rounded-[10px]
}
.cursor-pointer{
  cursor: pointer;
}