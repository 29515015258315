.custom-box-shadow{
    box-shadow: 0px 0px 5.3px 0px rgba(0, 0, 0, 0.1);
}
.card_shadow{
    border-radius: 10px;
    box-shadow: 0px 0px 10px 0px #0000001A;

}
.cursor-pointer{
    cursor: pointer;
}
.note-input{
    border-width:0 !important;   
    
}
.note-input:focus {
    outline: none !important;
   
}
.dashed-border-large {
    border-style: dashed;
    border-width: 0 0 0 1px;
    border-color: #000; /* Adjust the color as needed */
    border-image: repeating-linear-gradient(45deg, #7E7E7E, #7E7E7E 5px, transparent 5px, transparent 10px) 1;
  }
  .custom-list {
    list-style: none; /* Remove default list styling */
    padding-left: 0; /* Remove default padding */
  }
  
  .custom-list-item {
    position: relative;
    padding-left: 2em; /* Space for the custom circle */
    margin-bottom: 0.5em; /* Optional: Space between list items */
  }
  
  .custom-list-item::after {
    content: "";
    position: absolute;
    left: 0;
    top: 50%;
    transform: translateY(-50%);
    width: 1.5em; /* Size of the circle */
    height: 1.5em; /* Size of the circle */
    background-color: red; /* Color of the circle */
    border-radius: 50%; /* Makes it a circle */
  }
  